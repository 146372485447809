import { Box, CircularProgress } from '@mui/material';
import FlagList from 'features/flag';
import AddFlag from 'features/flag/add';
import EditFlag from 'features/flag/edit';
import React, { Suspense } from 'react';
import { Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom';

const MainLayout = React.lazy(() => import('layout/MainLayout'));
const Login = React.lazy(() => import('features/auth/login'));
const CleanIpList = React.lazy(() => import('features/clean-ip'));
const AddCleanIp = React.lazy(() => import('features/clean-ip/add'));
const EditCleanIp = React.lazy(() => import('features/clean-ip/edit'));
const HostList = React.lazy(() => import('features/host'));
const AddHost = React.lazy(() => import('features/host/add'));
const EditHost = React.lazy(() => import('features/host/edit'));
const ConfigurationList = React.lazy(() => import('features/configuration'));
const AddConfiguration = React.lazy(() => import('features/configuration/add'));
const EditConfiguration = React.lazy(() => import('features/configuration/edit'));
const ZoneList = React.lazy(() => import('features/zone'));
const DnsList = React.lazy(() => import('features/zone/dns-list'));
const AddBulkZone = React.lazy(() => import('features/zone/add-bulk-zone'));
const ServerList = React.lazy(() => import('features/server'));
const AddServer = React.lazy(() => import('features/server/add'));
const EditServer = React.lazy(() => import('features/server/edit'));
const ChangeDns = React.lazy(() => import('features/server/change-dns'));
const NotFound = React.lazy(() => import('features/not-found'));
const InternalServerError = React.lazy(() => import('features/internal-server-error'));

function RequireAuth() {
  const location = useLocation();
  // if (!auth.user.name) {
  //   return <Navigate to="/login" state={{ from: location }} />;
  // }

  return <Outlet />;
}

function MainRoutes() {
  return (
    <Suspense
      fallback={
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      }
    >
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route element={<MainLayout />}>
          <Route element={<RequireAuth />}>
            <Route path="/clean-ip">
              <Route path="/clean-ip/list" element={<CleanIpList />} />
              <Route path="/clean-ip/Add" element={<AddCleanIp />} />
              <Route path="/clean-ip/edit/:id" element={<EditCleanIp />} />
            </Route>

            <Route path="/host">
              <Route path="/host/list" element={<HostList />} />
              <Route path="/host/Add" element={<AddHost />} />
              <Route path="/host/edit/:id" element={<EditHost />} />
            </Route>

            <Route path="/flag">
              <Route path="/flag/list" element={<FlagList />} />
              <Route path="/flag/Add" element={<AddFlag />} />
              <Route path="/flag/edit/:id" element={<EditFlag />} />
            </Route>

            <Route path="/configuration">
              <Route path="/configuration/list" element={<ConfigurationList />} />
              <Route path="/configuration/Add" element={<AddConfiguration />} />
              <Route path="/configuration/edit/:id" element={<EditConfiguration />} />
            </Route>

            <Route path="/zone">
              <Route path="/zone/add" element={<AddBulkZone />} />
              <Route path="/zone/list" element={<ZoneList />} />
              <Route path="/zone/:id/dns-list" element={<DnsList />} />
            </Route>

            <Route path="/server">
              <Route path="/server/add" element={<AddServer />} />
              <Route path="/server/edit/:id" element={<EditServer />} />
              <Route path="/server/list" element={<ServerList />} />
              <Route path="/server/:id/change-dns" element={<ChangeDns />} />
            </Route>
          </Route>
        </Route>
        <Route path="/internal-server-error" element={<InternalServerError />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default MainRoutes;

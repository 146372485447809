/* eslint-disable import/no-anonymous-default-export */
export default {
  auth: {
    login: 'Authenticate/login'
  },
  cleanIp: {
    _: 'CleanIp'
  },
  host: {
    _: 'Host'
  },
  flag: {
    _: 'Flag'
  },
  configuration: {
    _: 'Configuration'
  },
  zone: {
    _: 'Cloudflare',
    bulkZone: 'Cloudflare/bulk-zone',
    ListZone: 'Cloudflare/get-zone-list',
    changeStatusPending: 'Cloudflare/change-status-pending',
    deleteZone: 'Cloudflare/delete-zone',
    deleteDnsRecord: 'Cloudflare/delete-dns-record',
    filterZone: 'Cloudflare/filter-zone',
    activeIZone: 'Cloudflare/active-zone'
  },
  server: {
    _: 'Server',
    createServer: 'Server/add-server',
    zoneOption: 'Cloudflare/ready-zone-list',
    editServer: 'Server/edit-server',
    changeDns: 'Server/change-dns-server',
    deleteServer: 'Server/delete-server',
    detailServer: 'Server/server-detail',
    replaceDns: 'Server/replace-dns',
    bulkChangeDns: 'Server/bulk-change-dns',
    changeStatus: 'Server/change-status'
  }
};

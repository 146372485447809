import APIs from 'utils/APIs';
import { apiSlice } from 'store/api-slice';
import { ICreateFlag, IEditFlag, IFlag } from 'models/flag';

export const flagApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createFlag: builder.mutation<any, ICreateFlag>({
      query: (body) => ({
        url: `${APIs.flag._}`,
        method: 'POST',
        body
      })
    }),
    editFlag: builder.mutation<any, IEditFlag>({
      query: (body) => ({
        url: `${APIs.flag._}/${body.id}`,
        method: 'PUT',
        body
      })
    }),
    listFlag: builder.query<IFlag[], void>({
      query: (param) => `${APIs.flag._}`
      // transformResponse: ({ data }) => data
    }),
    deleteFlag: builder.mutation<void, number>({
      query: (id) => ({
        url: `${APIs.flag._}/${id}`,
        method: 'DELETE'
      })
    }),
    getFlag: builder.query<IFlag, number>({
      query: (id) => `${APIs.flag._}/${id}`
    })
  })
});

export const {
  useCreateFlagMutation,
  useDeleteFlagMutation,
  useLazyListFlagQuery,
  useEditFlagMutation,
  useLazyGetFlagQuery,
  useListFlagQuery
} = flagApiSlice;

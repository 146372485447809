import Routes from 'routes';
import ThemeCustomization from 'themes';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import Customization from 'components/Customization';

const App = () => {
  return (
    <ThemeCustomization>
      <RTLLayout>
        <ScrollTop>
          <>
            <Notistack>
              <Routes />
              <Customization />
              <Snackbar />
            </Notistack>
          </>
        </ScrollTop>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;

import { useNavigate } from 'react-router-dom';
import { FormHelperText, Grid, InputLabel, OutlinedInput, Stack, Button } from '@mui/material';
import { joiResolver } from '@hookform/resolvers/joi';
import { Controller, useForm } from 'react-hook-form';
import Joi from 'joi';
import MainCard from 'components/MainCard';
import { openSnackbar } from 'store/reducers/snackbar';
import { dispatch } from 'store';
import { useCreateFlagMutation } from '../flag-api-slice';
import { ICreateFlag } from 'models/flag';

const AddFlag = () => {
  const navigate = useNavigate();
  const [postData, { error, isError, isLoading }] = useCreateFlagMutation();

  const schema = Joi.object({
    persianLocation: Joi.string().required().messages({
      'string.empty': 'required'
    }),
    location: Joi.string().required().messages({
      'string.empty': 'required'
    }),
    telegramFlag: Joi.string().required().messages({
      'string.empty': 'required'
    }),
    flagLink: Joi.string().required().messages({
      'string.empty': 'required'
    })
  });

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<ICreateFlag>({
    resolver: joiResolver(schema)
  });

  const onSubmit = async () => {
    try {
      await postData(getValues()).unwrap();

      dispatch(
        openSnackbar({
          open: true,
          message: 'success',
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
      navigate('/flag/list');
    } catch (err) {}
  };

  return (
    <MainCard title="Create New Flag">
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3.5}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="location">Location</InputLabel>
              <Controller
                name="location"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="location"
                    type="text"
                    placeholder="Enter Your Location"
                    fullWidth
                    error={Boolean(errors?.location)}
                    {...field}
                  />
                )}
              />
              {errors?.location && <FormHelperText error>{errors?.location?.message}</FormHelperText>}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="persianLocation">Persian Location</InputLabel>
              <Controller
                name="persianLocation"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="persianLocation"
                    type="text"
                    placeholder="Enter Your Persian Location"
                    fullWidth
                    error={Boolean(errors?.persianLocation)}
                    {...field}
                  />
                )}
              />
              {errors?.persianLocation && <FormHelperText error>{errors?.persianLocation?.message}</FormHelperText>}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="telegramFlag">Telegram Flag</InputLabel>
              <Controller
                name="telegramFlag"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="telegramFlag"
                    type="text"
                    placeholder="Enter Your Telegram Flag"
                    fullWidth
                    error={Boolean(errors?.telegramFlag)}
                    {...field}
                  />
                )}
              />
              {errors?.telegramFlag && <FormHelperText error>{errors?.telegramFlag?.message}</FormHelperText>}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="flagLink">Flag Link</InputLabel>
              <Controller
                name="flagLink"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="flagLink"
                    type="text"
                    placeholder="Enter Your Flag Link"
                    fullWidth
                    error={Boolean(errors?.flagLink)}
                    {...field}
                  />
                )}
              />
              {errors?.flagLink && <FormHelperText error>{errors?.flagLink?.message}</FormHelperText>}
            </Stack>
          </Grid>

          {isError && (
            <Grid item xs={12} sm={6}>
              <FormHelperText error>
                {
                  // @ts-ignore
                  error?.data?.message
                }
              </FormHelperText>
            </Grid>
          )}

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-start">
              <Button disableElevation disabled={isLoading} type="submit" variant="contained" color="primary" sx={{ minWidth: 120 }}>
                submit
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
};

export default AddFlag;

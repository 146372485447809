import { ReactElement, useMemo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Stack, Button } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useTable, Column, HeaderGroup, Cell } from 'react-table';
import { useSticky } from 'react-table-sticky';
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import { EmptyTable } from 'components/third-party/ReactTable';
import { ThemeMode } from 'types/config';

const TableWrapper = styled('div')(({ theme }) => ({
  '.header': {
    position: 'sticky',
    zIndex: 1,
    width: 'fit-content'
  },
  '& th[data-sticky-td]': {
    position: 'sticky',
    zIndex: '5 !important'
  }
}));

interface TableProps {
  columns: Column[];
  data: [];
  title?: string;
  striped?: boolean;
  headerTable?: ReactElement;
}

function ReactTable({ columns, data, title, striped, headerTable }: TableProps) {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 50,
      width: 100,
      maxWidth: 400
    }),
    []
  );
  const theme = useTheme();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn
    },
    useSticky
  );

  return (
    <Stack spacing={10}>
      <MainCard title={title} content={false} secondary={headerTable}>
        <ScrollX sx={{ height: 650 }}>
          <TableWrapper>
            <Table {...getTableProps()} stickyHeader>
              <TableHead>
                {headerGroups.map((headerGroup: HeaderGroup<{}>) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: HeaderGroup<{}>) => {
                      return (
                        <TableCell sx={{ position: 'sticky !important' }} {...column.getHeaderProps([{ className: column.className }])}>
                          {column.render('Header')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()} {...(striped && { className: 'striped' })}>
                {rows.length > 0 ? (
                  rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <TableRow {...row.getRowProps()}>
                        {row.cells.map((cell: Cell) => {
                          return (
                            <TableCell
                              sx={{
                                bgcolor: theme.palette.mode === ThemeMode.DARK ? 'secondary.100' : 'common.white'
                              }}
                              {...cell.getCellProps([{ className: cell.column.className }])}
                            >
                              {cell.render('Cell')}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <EmptyTable msg="No Data" colSpan={12} />
                )}
              </TableBody>
            </Table>
          </TableWrapper>
        </ScrollX>
      </MainCard>
    </Stack>
  );
}

const StickyTable = ({
  columns,
  data,
  title,
  striped,
  headerTable
}: {
  data: [];
  columns: Column[];
  title?: string;
  striped?: boolean;
  headerTable?: ReactElement;
}) => {
  return <ReactTable columns={columns} data={data} title={title} striped={striped} headerTable={headerTable} />;
};

export default StickyTable;
